define("semente-web-app/models/pessoa", ["exports", "ember-data", "moment"], function (_exports, _emberData, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _emberData.default.Model.extend({
    name: _emberData.default.attr(),
    role: _emberData.default.attr(),
    email: _emberData.default.attr(),
    emailCadastrado: _emberData.default.attr(),
    codigoCadastro: _emberData.default.attr(),
    matricula: _emberData.default.attr(),
    genero: _emberData.default.attr(),
    nascimento: _emberData.default.attr(),
    nascimentoPlataforma: _emberData.default.attr(),
    password: _emberData.default.attr(),
    confirmpassword: _emberData.default.attr(),
    enabled: _emberData.default.attr(),
    ultimoacesso: _emberData.default.attr(),
    lastAccess: Ember.computed('ultimoacesso', function () {
      return (0, _moment.default)(this.get('ultimoacesso'), 'X').format('DD/MM/YYYY');
    }),
    nracessos: _emberData.default.attr(),
    emailsent: _emberData.default.attr(),
    welcomeEmail: _emberData.default.attr(),
    progressototal: _emberData.default.attr(),
    uriAvatar: _emberData.default.attr(),
    numeroCursos: _emberData.default.attr(),
    cursosConcluidos: _emberData.default.attr(),
    ano: _emberData.default.belongsTo('ano', {
      async: true
    }),
    area: _emberData.default.belongsTo('area', {
      async: true
    }),
    turmas: _emberData.default.hasMany('turma', {
      async: true
    }),
    sistema: _emberData.default.belongsTo('sistema', {
      async: true
    }),
    modulos: _emberData.default.hasMany('modulo', {
      async: true
    }),
    matriculas: _emberData.default.hasMany('matricula', {
      async: true
    }),
    instituicao: _emberData.default.belongsTo('instituicao', {
      async: true
    }),
    alternativas: _emberData.default.hasMany('alternativa', {
      async: true
    }),
    respostas: _emberData.default.hasMany('resposta', {
      async: true
    }),
    estadoVideos: _emberData.default.hasMany('estado-video', {
      async: true
    }),
    estadoVideosAlternativas: _emberData.default.hasMany('estado-video-alternativa', {
      async: true
    }),
    leituras: _emberData.default.hasMany('leitura', {
      async: true
    }),
    aplicacaoAulas: _emberData.default.hasMany('aplicacao-aula', {
      async: true
    }),
    aplicacaoQuestionarios: _emberData.default.hasMany('aplicacao-questionario', {
      async: true
    }),
    acompanhamentoDominios: _emberData.default.hasMany('acompanhamento-pessoa-dominio', {
      async: true
    }),
    historicoComps: _emberData.default.hasMany('historico-comp', {
      async: true
    }),
    plataformaAnos: _emberData.default.hasMany('plataforma-ano', {
      async: true
    }),
    anoAtual: _emberData.default.belongsTo('plataforma-ano', {
      async: true
    }),
    plataformaTurmas: _emberData.default.hasMany('plataforma-turma', {
      async: true
    }),
    dataVisualizacaoBiblioteca: _emberData.default.attr(),
    termoAceite: _emberData.default.attr(),
    senha: _emberData.default.attr(),
    nameLower: Ember.computed('name', function () {
      return this.get('name').toLowerCase();
    }),
    ejaAcessoS: _emberData.default.attr(),
    isEja: _emberData.default.attr(),
    isAplicador: _emberData.default.attr(),
    progressoAtividades: _emberData.default.hasMany('acompanhamento-atividade', {
      async: true
    }),
    linkAviso: _emberData.default.attr(),
    tourPages: _emberData.default.attr(),
    avaliacoes: _emberData.default.hasMany('avaliacao', {
      async: true
    }),
    lastDevice: _emberData.default.attr(),
    firstName: _emberData.default.attr()
  });
  _exports.default = _default;
});