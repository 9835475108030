define("semente-web-app/templates/login", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "IAGwhmF1",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[20,[\"loadComponent\"]]],null,{\"statements\":[[0,\"    \"],[1,[25,\"login-coreskills\",null,[[\"authenticate\",\"identification\",\"password\",\"user_email\",\"remember\",\"passwordVisibility\",\"forgotPass\",\"errorMessage\",\"success_mail\",\"cancelForgot\",\"error_forgot\",\"sendMail\"],[[25,\"action\",[[19,0,[]],\"authenticate\"],null],[20,[\"identification\"]],[20,[\"password\"]],[20,[\"user_email\"]],[20,[\"remember\"]],[25,\"action\",[[19,0,[]],\"passwordVisibility\"],null],[25,\"action\",[[19,0,[]],\"forgotPass\"],null],[20,[\"errorMessage\"]],[20,[\"success_mail\"]],[25,\"action\",[[19,0,[]],\"cancelPass\"],null],[20,[\"error_forgot\"]],[25,\"action\",[[19,0,[]],\"sendMail\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"\\n      \"],[1,[25,\"login-plataforma-semente\",null,[[\"authenticate\",\"identification\",\"authenticating\",\"password\",\"user_email\",\"remember\",\"passwordVisibility\",\"forgotPass\",\"errorMessage\",\"success_mail\",\"cancelPass\",\"error_forgot\",\"sendMail\"],[[25,\"action\",[[19,0,[]],\"authenticate\"],null],[20,[\"identification\"]],[20,[\"authenticating\"]],[20,[\"password\"]],[20,[\"user_email\"]],[20,[\"remember\"]],[25,\"action\",[[19,0,[]],\"passwordVisibility\"],null],[25,\"action\",[[19,0,[]],\"forgotPass\"],null],[20,[\"errorMessage\"]],[20,[\"success_mail\"]],[25,\"action\",[[19,0,[]],\"cancelPass\"],null],[20,[\"error_forgot\"]],[25,\"action\",[[19,0,[]],\"sendMail\"],null]]]],false],[0,\"\\n\\n\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "semente-web-app/templates/login.hbs"
    }
  });
  _exports.default = _default;
});