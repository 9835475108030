define("semente-web-app/models/arquivo", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _emberData.default.Model.extend({
    thumbnail: _emberData.default.attr(),
    name: _emberData.default.attr(),
    url: _emberData.default.attr(),
    tipo: _emberData.default.attr(),
    marketing: _emberData.default.belongsTo('marketing', {
      async: true
    })
  });
  _exports.default = _default;
});